import { CartResourceService } from 'services/generated/commerce-service/services/CartResourceService';
import type { ParsedCartDTO } from 'ui/types/cartTypes';
import { OpenAPI as CommerceOpenApi } from 'services/generated/commerce-service';
import { DEFAULT_LANGUAGE } from '@whoop/i18n';
import { env } from 'env';
import { parseCart } from '@/lib/utils/parseCart';

// --------------- CART ACTIONS --------------- //
// To appropriately set localizationPreferences on BE, Accept-Language header must be set
// TODO: Going to catch errors later and render error toast accordingly
CommerceOpenApi.BASE = env.NEXT_PUBLIC_COMMERCE_SERVICE_API;

export async function addToEcommCart(
  ...params: Parameters<typeof CartResourceService.addToCart>
): Promise<ParsedCartDTO> {
  try {
    CommerceOpenApi.HEADERS = {
      'Accept-Language': params[0].requestBody?.locale ?? DEFAULT_LANGUAGE,
    };
    const cartResponse = await CartResourceService.addToCart(...params);
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error(
      `Error adding item (sku: ${params[0].requestBody?.sku}) to cart`,
    );
  }
}

export async function addEngravingToEcommCart(
  ...params: Parameters<typeof CartResourceService.addEngravingsToCart>
): Promise<ParsedCartDTO> {
  try {
    CommerceOpenApi.HEADERS = {
      'Accept-Language': params[0].requestBody?.locale ?? DEFAULT_LANGUAGE,
    };
    const cartResponse = await CartResourceService.addEngravingsToCart(
      ...params,
    );
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error(
      `Error adding engraving (sku: ${params[0].requestBody?.sku}) to cart`,
    );
  }
}

export async function addPackToCart(
  ...params: Parameters<typeof CartResourceService.addPackToCart>
): Promise<ParsedCartDTO> {
  try {
    const cartResponse = await CartResourceService.addPackToCart(...params);
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error(
      `Error adding pack (sku: ${params[0].requestBody?.pack_sku}) to cart`,
    );
  }
}

export async function addWYWToCart(
  ...params: Parameters<typeof CartResourceService.addWywToCart>
): Promise<ParsedCartDTO> {
  try {
    const cartResponse = await CartResourceService.addWywToCart(...params);
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error(`Error adding whoop your way to cart`);
  }
}

export async function updateEcommCartQuantity(
  language: string,
  ...params: Parameters<typeof CartResourceService.updateLineItemQuantity>
): Promise<ParsedCartDTO> {
  try {
    CommerceOpenApi.HEADERS = {
      'Accept-Language': language,
    };
    const cartResponse = await CartResourceService.updateLineItemQuantity(
      ...params,
    );
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error(
      `Error updating item (sku: ${params[0].lineItemId}) in cart`,
    );
  }
}

export async function setShippingMethod(
  language: string,
  ...params: Parameters<typeof CartResourceService.setShippingMethod>
): Promise<ParsedCartDTO> {
  try {
    CommerceOpenApi.HEADERS = {
      'Accept-Language': language ?? DEFAULT_LANGUAGE,
    };
    const cartResponse = await CartResourceService.setShippingMethod(...params);
    return parseCart(cartResponse);
  } catch (err) {
    throw new Error('Error setting shipping method on cart');
  }
}
