import type { Variant } from 'services/generated/commerce-service';
import {
  getSizeLabel,
  getSwatchImage,
  getVariantColor,
  getInseamLabel,
  isWywVariant,
} from 'ui/utils/variantHelpers';
import { UnstyledButton } from 'ui/components/ButtonV2/Button';
import TinyBorderedCheckmark from 'ui/icons/icons/Tiny/TinyBorderedCheckmark';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

export type SwatchSize = 'small' | 'medium' | 'large';
export type SwatchType = 'color' | 'size' | 'inseam';

export interface RadioSwatchesPropsV2 {
  variants: Variant[];
  selectedSwatch: Variant;
  onClick: (variant: Variant) => void;
  onHover?: (variant?: Variant) => void;
  swatchSize: SwatchSize;
  swatchType: SwatchType;
  showCheckmark?: boolean;
  defaultSelection?: Variant;
  allowDeselect?: boolean;
  collapseByDefault?: boolean;
  disableWhenOOS?: boolean;
}

const SwatchesClassesMap = {
  small: {
    swatchContainerSizeClasses: 'flex items-center justify-center h-8 w-full',
    swatchSizeClasses: 'h-6 w-6',
    columnSize: 'grid-cols-[repeat(auto-fill,_minmax(32px,_1fr))]',
    swatchText: 'text-[8px] font-bold',
  },
  medium: {
    swatchContainerSizeClasses: 'flex items-center justify-center h-11 w-full',
    swatchSizeClasses: 'h-8 w-8',
    columnSize: 'grid-cols-[repeat(auto-fill,_minmax(44px,_1fr))]',
    swatchText: 'text-sm',
  },
  large: {
    swatchContainerSizeClasses: 'flex items-center justify-center h-14 w-full',
    swatchSizeClasses: 'h-10 w-10',
    columnSize: 'grid-cols-[repeat(auto-fill,_minmax(56px,_1fr))]',
    swatchText: 'text-sm',
  },
};

const swatchWidth = {
  small: 32,
  medium: 44,
  large: 56,
};

const disabledOrCrossoutStyles = [
  "after:absolute after:left-[calc(50%-1px)] after:top-0 after:h-full after:w-0.5 after:rotate-45 after:bg-black after:content-['']",
  "before:absolute before:left-[calc(50%-2px)] before:top-0 before:h-full before:w-1 before:rotate-45 before:bg-white/50 before:content-['']",
];

export default function RadioSwatchesV2({
  variants,
  selectedSwatch,
  onClick,
  onHover,
  swatchSize,
  swatchType,
  showCheckmark = false,
  defaultSelection,
  allowDeselect = false,
  collapseByDefault = false,
  disableWhenOOS = false,
}: RadioSwatchesPropsV2): JSX.Element {
  const classes = SwatchesClassesMap[swatchSize];

  const [isExpanded, setIsExpanded] = useState(!collapseByDefault);
  const [hiddenNumber, setHiddenNumber] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current === null) return;
    // The number of swatches we can show depends on the swatches width as well as the container width
    const maxVisibleOptions = Math.floor(
      // Container width minus "+n" text width
      (containerRef.current.clientWidth - 37) / swatchWidth[swatchSize],
    );
    // Then we can calculate the number of swatches that will be hidden until the component is expanded
    const hiddenOptionCount = variants.length - maxVisibleOptions;
    setHiddenNumber(Math.max(hiddenOptionCount, 0));
  }, [containerRef]);

  const getTitle = (variant: Variant): string => {
    switch (swatchType) {
      case 'color':
        return getVariantColor(variant) ?? '';
      case 'size':
        return getSizeLabel(variant);
      case 'inseam':
        return getInseamLabel(variant);
    }
  };

  const handleOnExpand = (): void => {
    setIsExpanded(true);
  };

  // total number of swatches
  const totalSwatches = variants.length;
  // highest swatch index that is visible. all higher indices will be hidden and shouldn't be clickable
  const visibleSwatchCutoff = isExpanded
    ? totalSwatches
    : totalSwatches - hiddenNumber - 1;

  return (
    <div className='flex flex-row justify-between'>
      <div
        className={clsx('grid w-full flex-wrap', classes.columnSize)}
        ref={containerRef}
      >
        {variants.map((variant, index) => {
          const selectedByDefault =
            defaultSelection && defaultSelection.sku === variant.sku;
          const isSelectedVariant = variant.sku === selectedSwatch.sku;
          const disableSwatch =
            (disableWhenOOS && !variant.in_stock) ||
            (isSelectedVariant && !allowDeselect);
          return (
            <div
              className={clsx(
                classes.swatchContainerSizeClasses,
                !isExpanded && index > visibleSwatchCutoff ? 'hidden' : '',
              )}
              key={variant.sku}
              onMouseEnter={() => onHover?.(variant)}
              onMouseLeave={() => onHover?.()}
            >
              <UnstyledButton
                className={clsx(
                  classes.swatchSizeClasses,
                  'relative rounded-full bg-cover shadow-inner shadow-black/20 outline outline-2 outline-offset-4 transition-all duration-300',
                  selectedByDefault || isSelectedVariant
                    ? 'outline-black'
                    : 'outline-transparent hover:outline-black/20',
                  !variant.in_stock && !isWywVariant(variant)
                    ? disabledOrCrossoutStyles
                    : '',
                )}
                disabled={disableSwatch}
                onClick={() =>
                  onClick(isSelectedVariant ? variants[0] : variant)
                }
                style={
                  swatchType === 'color'
                    ? {
                        backgroundImage: `url(${getSwatchImage(variant)})`,
                      }
                    : {}
                }
                title={getTitle(variant)}
              >
                {swatchType !== 'color' ? (
                  <div className={classes.swatchText}>
                    {swatchType === 'size'
                      ? getSizeLabel(variant)
                      : getInseamLabel(variant)}
                  </div>
                ) : null}
                {showCheckmark && (selectedByDefault || isSelectedVariant) ? (
                  <div className='flex h-full w-full items-center justify-center'>
                    <TinyBorderedCheckmark
                      fill='white'
                      height={16}
                      title='checked'
                      width={16}
                    />
                  </div>
                ) : null}
              </UnstyledButton>
            </div>
          );
        })}
      </div>
      {!isExpanded && hiddenNumber > 0 ? (
        <UnstyledButton
          className='cursor-pointer px-[5px] text-blue-600 underline'
          onClick={handleOnExpand}
        >
          +{hiddenNumber}
        </UnstyledButton>
      ) : null}
    </div>
  );
}
